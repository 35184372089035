import { Match, STATUS } from "../types/types";
import { getMatchStatusText } from "../utils";
import ballLogo from "../assets/icons/ball-logo.png";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppSelector } from "../hooks";
import { dbUpdateGroupMatch } from "../api/api";
import { SxProps, TextField } from "@mui/material";

interface Props {
  match: Match;
}

export const iconStyles: SxProps = {
  fontSize: "60px",
  color: "#3A3A3A",

  ":hover": {
    cursor: "pointer",
  },
};

function MatchData(props: Props) {
  const { match } = props;
  const isAdmin = useAppSelector((state) => state.app.admin);

  const getStatusColor = (status: STATUS) => {
    switch (status) {
      case STATUS.PLAYING:
        return "#2e7d32";
      case STATUS.PENDING:
        return "#ff6b00";
      case STATUS.FINISHED:
        return "white";
      default:
        return "white";
    }
  };

  const updateMatchPoints = (team: "homeTeamPoints" | "awayTeamPoints", newScore: number) => {
    if (newScore < 0) return;
    if (!match?.id) return;
    dbUpdateGroupMatch(match.id, team, newScore);
  };

  return (
    <>
      <div className="match-update-dialog-content">
        <div className="match-update-container">
          <div className="row">
            {isAdmin && match?.status === STATUS.PLAYING && (
              <>
                <KeyboardArrowUpIcon
                  onClick={() => updateMatchPoints("homeTeamPoints", match.homeTeamPoints + 1)}
                  sx={iconStyles}
                />
                <KeyboardArrowUpIcon
                  onClick={() => updateMatchPoints("awayTeamPoints", match.awayTeamPoints + 1)}
                  sx={iconStyles}
                />
              </>
            )}
          </div>
          <div className="row">
            <div className="column">
              <img src={match?.homeTeam?.club?.logo ? match?.homeTeam?.club?.logo : ballLogo} />
              <p className="team-name">{match?.homeTeam?.teamName}</p>
            </div>
            <div className="column">
              <div className="center-column-container">
                {match?.status === STATUS.PENDING ? (
                  "VS"
                ) : (
                  <div className="result">
                    <CustomTextField
                      onChange={(newValue) => updateMatchPoints("homeTeamPoints", newValue)}
                      value={match.homeTeamPoints}
                    />
                    -
                    <CustomTextField
                      onChange={(newValue) => updateMatchPoints("awayTeamPoints", newValue)}
                      value={match.awayTeamPoints}
                    />
                  </div>
                )}
                {match && (
                  <div className="status" style={{ color: getStatusColor(match?.status) }}>
                    {getMatchStatusText(match?.status)}
                  </div>
                )}
              </div>
            </div>
            <div className="column">
              <img src={match?.awayTeam?.club?.logo ? match?.awayTeam?.club?.logo : ballLogo} />
              <p className="team-name">{match?.awayTeam?.teamName}</p>
            </div>
          </div>
          <div className="row">
            {isAdmin && match?.status === STATUS.PLAYING && (
              <>
                <KeyboardArrowDownIcon
                  onClick={() => updateMatchPoints("homeTeamPoints", match.homeTeamPoints - 1)}
                  sx={iconStyles}
                />
                <KeyboardArrowDownIcon
                  onClick={() => updateMatchPoints("awayTeamPoints", match.awayTeamPoints - 1)}
                  sx={iconStyles}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

interface TextFieldProps {
  value: number;
  onChange: (newValue: number) => void;
}

const CustomTextField = (props: TextFieldProps) => {
  const { onChange, value } = props;

  return (
    <TextField
      type="number"
      onChange={(e) => onChange(parseInt(e.target.value))}
      value={value}
      InputProps={{
        sx: {
          backgroundColor: "transparent",
          color: "white",
          height: "30px", // Increase height to fit larger text
          display: "flex",
          alignItems: "center",
          border: "none",
          "& input": {
            letterSpacing: 3,
            color: "white",
            fontSize: "1.5rem", // Larger text
            padding: "0px 0px", // Adjust padding for better fit
            textAlign: "center",
            border: "none",
            outline: "none",
            width: "100%", // Ensure it takes full width
            "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
              display: "none",
            },
            "-moz-appearance": "textfield",
          },
          "& fieldset": { border: "none" },
          "&:hover fieldset": { border: "none" },
          "&.Mui-focused fieldset": { border: "none" },
        },
      }}
      InputLabelProps={{
        sx: { color: "white" },
      }}
    />
  );
};

export default MatchData;
