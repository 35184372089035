import avatar0 from "../../../assets/avatars/0.png";
import avatar1 from "../../../assets/avatars/1.png";
import avatar2 from "../../../assets/avatars/2.png";
import avatar3 from "../../../assets/avatars/3.png";
import avatar4 from "../../../assets/avatars/4.png";
import avatar5 from "../../../assets/avatars/5.png";
import avatar6 from "../../../assets/avatars/6.png";
import avatar7 from "../../../assets/avatars/7.png";
import avatar8 from "../../../assets/avatars/8.png";
import avatar9 from "../../../assets/avatars/9.png";

export const avatars = [avatar0, avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9];
