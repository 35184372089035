import React, { useEffect, useState } from "react";
import { TextField, Stack, Button, Container, Typography, FormHelperText } from "@mui/material";
import { dbUserType, getPlayers, getTeamById, submitPlayers } from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { ROLES, TeamType } from "../../types/types";
import NotificationService from "../../components/notification/NotificationService";
import { getGuid } from "../../utils";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ballLogo from "../../assets/icons/ball-logo.png";


const DynamicInputList: React.FC = () => {
  const MAX_FIELDS = 9;
  const { id } = useParams();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState<dbUserType[]>([]);
  const [originalInputs, setOriginalInputs] = useState<dbUserType[]>([]);
  const [team, setTeam] = useState<TeamType>();
  const [errors, setErrors] = useState<string[]>(Array(MAX_FIELDS).fill(""));

  useEffect(() => {
    if (!id) return;

    // Fetch players
    getPlayers(id).then((response) => {
      if (response) {
        // Fill remaining fields if there are less than MAX_FIELDS players
        const filledInputs = [
          ...response.sort((a, b) => parseInt(a.number ?? "") - parseInt(b.number ?? "")),
          ...Array(MAX_FIELDS - response.length).fill({
            firstname: "",
            lastname: "",
            number: "",
            role: ROLES.PLAYER,
            team: id,
          }),
        ];

        setInputs(filledInputs);
        setOriginalInputs(filledInputs);
      } else {
        // If no players, initialize with MAX_FIELDS empty fields
        const newInputs = Array(MAX_FIELDS).fill({
          firstname: "",
          lastname: "",
          number: "",
          role: ROLES.PLAYER,
          team: id,
        });
        setInputs(newInputs);
        setOriginalInputs(newInputs);
      }
    });
  }, [id]);

  const handleInputChange = (index: number, field: "firstname" | "lastname" | "number", value: string) => {
    const newInputs = [...inputs];
    newInputs[index] = { ...newInputs[index], [field]: value };
    setInputs(newInputs);

    const newErrors = [...errors];
    newErrors[index] = "";
    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = inputs.map((input) => {
      if (!input.firstname || !input.lastname || !input.number) {
        return "All fields are required.";
      }
      return "";
    });
    setErrors(newErrors);

    return newErrors.every((error) => error === "");
  };

  const hasChanges = JSON.stringify(inputs) !== JSON.stringify(originalInputs);

  const handleSubmit = () => {
    if (!id || !validateForm()) return;

    submitPlayers(id, inputs.map(obj => {
      return {
        ...obj,
        id: getGuid()
      }
    }))
      .then((response) => {
        if (response) {
          setInputs(response);
          setOriginalInputs(response);
          NotificationService.showToast({
            severity: "success",
            text: "Holdkortet blev opdateret.",
            duration: 3000,
          });
        }
      })
      .catch(() => {
        NotificationService.showToast({
          severity: "error",
          text: "Der skete en uventet fejl. Prøv igen senere, eller kontakt turneringsarrangøren.",
          duration: 3000,
        });
      });
  };

  const handleRemove = (index: number) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
    setErrors(errors.filter((_, i) => i !== index));
  };

  const handleAdd = () => {
    if (inputs.length < MAX_FIELDS && id) {
      setInputs([
        ...inputs,
        { id: getGuid(), firstname: "", lastname: "", number: "", role: ROLES.PLAYER, team: id } as dbUserType,
      ]);
      setErrors([...errors, ""]);
    }
  };

  useEffect(() => {
    if (!id) return;
    getTeamById(id)
      .then((response) => {
        setTeam(response);
      })
      .catch(() => {
        navigate("error");
      });
  }, [id]);

  console.log(inputs)


  return (
    <div className="edit-team-list-page">
      <Container maxWidth="xl">
        <Stack spacing={2} >
          <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} paddingBottom={'20px'} >
            <img src={team?.club?.logo ?? ballLogo} height={50} width={50} style={{ marginRight: '20px' }} />
            <Typography variant="h3" sx={{ color: "white", textAlign: 'center' }}>
              {team?.teamName}
            </Typography>
            <img src={team?.club?.logo ?? ballLogo} height={50} width={50} style={{ marginLeft: '20px' }} />
          </Stack>
          <Stack spacing={2} width="100%" alignItems={'center'}>
            {inputs.map((input, index) => (
              <Stack key={index} flexDirection={"row"} alignItems={"center"}>
                <TextField
                  sx={{ width: "80px" }}
                  variant="outlined"
                  label={`No.`}
                  value={input.number}
                  onChange={(e) => handleInputChange(index, "number", e.target.value)}
                  InputProps={{ style: { color: "white" }, inputProps: { min: 1, step: 1, pattern: "[0-9]*" } }}
                  type="number"
                  error={!!errors[index]}
                />
                <TextField
                  sx={{ width: "150px", marginLeft: "20px" }}
                  variant="outlined"
                  label={`First Name`}
                  value={input.firstname}
                  onChange={(e) => handleInputChange(index, "firstname", e.target.value)}
                  InputProps={{ style: { color: "white" } }}
                  error={!!errors[index]}
                />
                <TextField
                  sx={{ width: "150px", marginLeft: "20px" }}
                  variant="outlined"
                  label={`Last Name`}
                  value={input.lastname}
                  onChange={(e) => handleInputChange(index, "lastname", e.target.value)}
                  InputProps={{ style: { color: "white" } }}
                  error={!!errors[index]}
                />
                <DeleteOutlineIcon color="error" sx={{ marginLeft: "15px" }} onClick={() => handleRemove(index)} />
              </Stack>
            ))}
            <Stack spacing={2}>
              {inputs.length < MAX_FIELDS &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAdd}
                >
                  Add Player
                </Button>}
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!hasChanges}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};

export default DynamicInputList;
