import React, { useState, useEffect, useMemo } from "react";
import { useSprings, animated } from "@react-spring/web";

// Function to generate a random letter
const randomLetter = (): string => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return letters[Math.floor(Math.random() * letters.length)];
};

interface ShuffleTextProps {

}

const ShuffleText: React.FC<ShuffleTextProps> = ({ }) => {
  const [letters, setLetters] = useState<string[]>('HELLO WORLD YE'.split(""));

  // Springs for each letter animation
  const springs = useSprings(
    letters.length,
    letters.map((letter, index) => ({
      opacity: 1,
      transform: `scale(1)`, // Animation starting state
      config: { tension: 180, friction: 12 },
      reset: true,
      reverse: index % 2 === 0, // Optional reverse effect
    }))
  );

  // Shuffle letters function
  const shuffleLetters = () => {
    setLetters(prevLetters =>
      prevLetters.map(() => randomLetter()) // Replace each letter with a random one
    );
  };

  // Shuffle letters indefinitely every randomSteps cycles
  useEffect(() => {
    const intervalId = setInterval(() => {
      shuffleLetters();
    }, 70); // Change letters every 100ms (you can adjust this)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <div>
      {springs.map((style, index) => (
        <animated.span
          key={index}
          className='animated-text'
          style={{
            ...style,
          }}
        >
          {letters[index]}
        </animated.span>
      ))}
    </div>
  );
};

const App: React.FC = () => {

  const delay = useMemo(() => {
    return Math.random() * 1
  }, [])

  return (
    <div className="shuffle-text-wrapper opacity-animation">
      <ShuffleText />
      <div className="white-dot" style={{ animationDelay: `${delay}s` }} />
    </div>
  );
};

export default App;
