import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import TextField from "@mui/material/TextField";
import { ROLES, TeamType } from "../../../types/types";
import { dbUpdateUserDetails, dbCreateUser, sendResetPasswordLink } from "../../../api/api";
import { addUser } from "../../../features/users/usersSlice";
import NotificationService from "../../../components/notification/NotificationService";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { validateEmail } from "../../../utils";

interface Props {
  selectedTeam?: TeamType;
}

const CreateUser = (props: Props) => {
  const editingUser = useAppSelector((state) => state.users.editingUser);
  const admin = useAppSelector((state) => state.app.admin);
  const [firstname, setFirstname] = useState(editingUser?.firstname ?? "");
  const [lastname, setLastname] = useState(editingUser?.lastname ?? "");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));

  const updateUserInfo = () => {
    /*
    if (!editingUser?.id || !editingUser) return;
    dbUpdateUserDetails(editingUser.id, {
      ...editingUser,
      firstname,
      lastname,
    })
      .then(() => {
        NotificationService.showToast({
          severity: "success",
          text: "Bruger blevet succesfuldt opdateret.",
          duration: 3000,
        });
        navigate(ROUTES.ADMIN_USERS);
      })
      .catch(() => {
        NotificationService.showToast({
          severity: "error",
          text: "Bruger kunne ikke opdateres.",
          duration: 3000,
        });
      });
      */
  };

  const submitNewUser = () => {
    if (!admin?.expiration) return;

    dbCreateUser(email, password)
      .then((id) => {
        if (id) {
          dbUpdateUserDetails(id, {
            id,
            firstname,
            lastname,
            email,
            expiration: admin.expiration ?? 0,
            role: ROLES.EDITOR,
            number: "",
          })
            .then((id) => {
              dispatch(
                addUser({
                  id,
                  firstname,
                  lastname,
                  email,
                  expiration: admin.expiration,
                  role: ROLES.EDITOR,
                  number: "",
                })
              );
            })
            .then(() => {
              NotificationService.showToast({
                severity: "success",
                text: "Brugeren blev oprettet!",
                duration: 3000,
              });
              navigate(ROUTES.SUPER_ADMIN_USERS);
            })
            .catch(() => {
              NotificationService.showToast({
                severity: "error",
                text: "Brugeren blev oprettet, men der kunne ikke gemmes bruger-oplysninger.",
                duration: 3000,
              });
            });
        }
      })
      .catch((e) => {
        console.error(e);
        NotificationService.showToast({
          severity: "error",
          text: "Kunne ikke oprette bruger",
          duration: 3000,
        });
        debugger;
      });
  };

  useEffect(() => {
    if (editingUser) {
      if (firstname === "" || lastname === "") {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      if (firstname === "" || lastname === "" || !validateEmail(email) || password === "") {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [lastname, firstname, email, password]);

  const sendResetPasswordLinkHandler = () => {
    if (!editingUser?.email) return;
    sendResetPasswordLink(editingUser.email)
      .then(() => {
        NotificationService.showToast({
          text: `En email er blevet sendt til ${editingUser.email}.`,
          severity: "success",
          duration: 5000,
        });
        navigate(ROUTES.SUPER_ADMIN_USERS);
      })
      .catch(() => {
        NotificationService.showToast({
          text: `Kunne ikke nulstille adgangskoden.`,
          severity: "error",
          duration: 5000,
        });
      });
  };

  return (
    <>
      <Container maxWidth={lgUp ? "sm" : "lg"}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="flex" spacing={4}>
            <Typography variant="h4">{editingUser ? "Opdater bruger" : "Opret bruger"}</Typography>
          </Stack>
          <Card>
            <form autoComplete="off" noValidate onSubmit={() => {}}>
              <Card>
                <CardHeader subheader="Udfyld følgende oplysninger:" title={editingUser ? "Opdater bruger" : "Ny bruger"} />
                <CardContent sx={{ pt: 0 }}>
                  <Box sx={{ m: -1.5 }}>
                    <Grid container>
                      <Grid xs={12} md={6} padding={1} item>
                        <TextField
                          fullWidth
                          label="Fornavn"
                          name="firstName"
                          onChange={(e) => setFirstname(e.target.value)}
                          required
                          value={firstname}
                        />
                      </Grid>
                      <Grid xs={12} md={6} padding={1} item>
                        <TextField
                          fullWidth
                          label="Efternavn"
                          name="lastName"
                          onChange={(e) => setLastname(e.target.value)}
                          required
                          value={lastname}
                        />
                      </Grid>
                      {!editingUser && (
                        <>
                          <Grid xs={12} md={12} padding={1} item>
                            <TextField
                              fullWidth
                              label="Email"
                              name="email"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                              value={email}
                            />
                          </Grid>
                          <Grid xs={12} md={12} padding={1} item>
                            <TextField
                              fullWidth
                              label="Adgangskode"
                              name="password"
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              value={password}
                              type="password"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: "space-between" }}>
                  {editingUser ? <Button onClick={sendResetPasswordLinkHandler}>Nulstil adgangskode</Button> : <div />}
                  <div>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(ROUTES.SUPER_ADMIN_USERS)}
                      sx={{ margin: "0px 10px" }}
                    >
                      Fortryd
                    </Button>
                    <Button disabled={error} variant="contained" onClick={editingUser ? updateUserInfo : submitNewUser}>
                      {editingUser ? "Opdater" : "Opret"}
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </form>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default CreateUser;
