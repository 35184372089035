import { Match } from "../../types/types";
import { Nationality, Pot, ExtendedTeamType } from "./data";
import Denmark from "./images/nationalities/denmark.png";
import Norway from "./images/nationalities/norway.png";
import England from "./images/nationalities/england.jpg";
import Belgium from "./images/nationalities/belgium.jpg";
import France from "./images/nationalities/france.png";
import Netherlands from "./images/nationalities/netherlands.png";
import Switzerland from "./images/nationalities/switzerland.png";
import Germany from "./images/nationalities/germany.png";

export const getEmoji = (nationality?: Nationality) => {
  if (!nationality) return "";
  switch (nationality) {
    case Nationality.DENMARK:
      return Denmark;
    case Nationality.NORWAY:
      return Norway;
    case Nationality.ENGLAND:
      return England;
    case Nationality.BELGIUM:
      return Belgium;
    case Nationality.FRANCE:
      return France;
    case Nationality.NETHERLANDS:
      return Netherlands;
    case Nationality.SWITZERLAND:
      return Switzerland;
    case Nationality.GERMANY:
      return Germany;
    default:
      "";
  }
};

const randomGenerator = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const findOpponent = (myTeam: ExtendedTeamType, searchPot: Pot, existingMatches: Match[]) => {
  // Club filtering
  const filteredPot = searchPot.teams.filter((team) => team.club?.id !== myTeam.club?.id);

  // Pot filtering
  let validModstandere = filteredPot.filter(
    (team) => !team.potMatches.includes(searchPot.potId) && !team.potMatches.includes(myTeam.potId)
  );

  // Man kan max møde 2 hold fra samme land

  const mostFrequentNation = getMostFrequentNation(myTeam);

  // Hvis der er allerede er 2 modstandere fra denne nation, må vi ikke trække flere hold fra denne nation
  if (mostFrequentNation.numberOfOpponents === 3) {
    validModstandere = validModstandere.filter((team) => team.nationality !== mostFrequentNation.nationality);
  }

  const randomIndex = randomGenerator(validModstandere.length);

  const opponent = validModstandere[randomIndex];

  return opponent;
};

export const getMostFrequentNation = (myTeam: ExtendedTeamType) => {
  // Lav en liste med alle nationaliteter
  let nationalities = Object.values(Nationality).map((obj) => {
    return {
      nationality: obj,
      numberOfOpponents: 0,
    };
  });

  // Find ud af hvor mange forskellige lande de nuværende modstanderne kommer fra
  myTeam.opponents.forEach((op) => {
    nationalities = nationalities.map((n) => {
      if (n.nationality === op.nationality) {
        return { ...n, numberOfOpponents: n.numberOfOpponents + 1 };
      } else {
        return n;
      }
    });
  });

  // Returner den nation hvor flest modstandere kommer fra
  return nationalities.sort((a, b) => b.numberOfOpponents - a.numberOfOpponents)[0];
};

export function getRootDomain() {
  const hostname = window.location.hostname; // e.g., "localhost" or "sub.example.com"
  const port = window.location.port; // e.g., "3000"

  let rootDomain;

  if (hostname === "localhost" || /^\d+\.\d+\.\d+\.\d+$/.test(hostname)) {
    rootDomain = hostname; // Keep "localhost" or IP as is
  } else {
    const parts = hostname.split(".");
    rootDomain = parts.slice(-2).join("."); // Extract "example.com"
  }

  return port ? `${rootDomain}:${port}` : rootDomain;
}
