import { Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
  EmailOutlined,
  HomeRepairServiceOutlined,
  HouseOutlined,
  PhoneAndroidOutlined,
  PhonelinkLockOutlined,
} from "@mui/icons-material";

function ContactPage() {
  return (
    <div className="contact-page">
      <Typography sx={{ textAlign: "center", marginTop: "50px", color: "white", width: "100%" }} variant="h4">
        Contact
      </Typography>
      <Typography sx={{ textAlign: "left", marginTop: "20px", color: "white" }} variant="body1">
        If you have any inquiries about our services, feel free to get in touch.
      </Typography>
      <Typography sx={{ textAlign: "left", marginTop: "20px", marginBottom: "30px", color: "white" }} variant="body1">
        We strive to respond to all messages as quickly as possible. For urgent matters, we recommend calling us directly.
      </Typography>
      <div className="contact-row">
        <Typography
          sx={{ display: "flex", alignItems: "center", textAlign: "left", marginTop: "30px", color: "white" }}
          variant="body1"
        ></Typography>
        <EmailOutlined style={{ marginRight: "10px" }} /> contact@tamil-football.dk
      </div>
      <div className="contact-row">
        <Typography
          sx={{ display: "flex", alignItems: "center", textAlign: "left", marginTop: "30px", color: "white" }}
          variant="body1"
        ></Typography>
        <PhoneAndroidOutlined style={{ marginRight: "10px" }} /> +45 28 60 66 68
      </div>
      <div className="contact-row">
        <Typography
          sx={{ display: "flex", alignItems: "center", textAlign: "left", marginTop: "30px", color: "white" }}
          variant="body1"
        ></Typography>
        <HouseOutlined style={{ marginRight: "10px" }} /> Prins Christians Vænge 135, 8660 Skanderborg
      </div>
    </div>
  );
}

export default ContactPage;
