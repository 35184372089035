import { Club, TeamType } from "../../types/types";
import { getGuid } from "../../utils";
import DantamLogo from "./images/logos/dantam.png";
import UniqueLogo from "./images/logos/unique.png";
import TfcFredericiaLogo from "./images/logos/tfc.png";
import NordavindLogo from "./images/logos/nordavind.png";
import SønderborgLogo from "./images/logos/sønderborg.png";
import OtscLogo from "./images/logos/otsc.png";
import Crew74Logo from "./images/logos/crew74.png";
import GtfcLogo from "./images/logos/gtfc.png";
import EverestLogo from "./images/logos/everest.png";
import StovnerLogo from "./images/logos/stovner.png";
import HobroLogo from "./images/logos/hobro.png";
import AarhusSkLogo from "./images/logos/aarhussk.png";
import NtskLogo from "./images/logos/ntsk.png";
import RoyalBernLogo from "./images/logos/royalbern.png";
import TfcBredaLogo from "./images/logos/breda.png";
import DrammenLogo from "./images/logos/dtsk.png";
import IllampuyalLogo from "./images/logos/ilampuyal.png";
import StPatricksLogo from "./images/logos/stpatricks.png";
import TikiTakaLogo from "./images/logos/tikitaka.png";
import BlueStarsLogo from "./images/logos/bluestars.png";
import EnnepetalLogo from "./images/logos/tsvennepetal.png";
import VejleLogo from "./images/logos/vejlepng.png";
import grindstedYoungstarsLogo from "./images/logos/youngstars.png";
import MinnelLogo from "./images/logos/minnel.png";
import SvendborgLogo from "./images/logos/svendborg.png";

export enum Nationality {
  DENMARK = "DENMARK",
  NORWAY = "NORWAY",
  NETHERLANDS = "NETHERLANDS",
  ENGLAND = "ENGLAND",
  FRANCE = "FRANCE",
  SWITZERLAND = "SWITZERLAND",
  BELGIUM = "BELGIUM",
  GERMANY = "GERMANY",
}

const DantamIf = { id: "dantamif", name: "Dantam IF", logo: DantamLogo } as Club;
const UniqueFc = { id: "uniquefc", name: "Unique FC", logo: UniqueLogo } as Club;
const TfcFredericia = { id: "tfcFredericia", name: "TFC Fredericia", logo: TfcFredericiaLogo } as Club;
const Nordavind = { id: "nordavind", name: "Nordavind", logo: NordavindLogo } as Club;
const Sønderborg = { id: "sønderborg", name: "Sønderborg", logo: SønderborgLogo } as Club;
const Otsc = { id: "otsc", name: "OTSC", logo: OtscLogo } as Club;
const Crew74 = { id: "crew74", name: "Crew74", logo: Crew74Logo } as Club;
const Gtfc = { id: "gtfc", name: "GTFC", logo: GtfcLogo } as Club;
const Everest = { id: "everest", name: "Everest", logo: EverestLogo } as Club;
const Hobro = { id: "hobro", name: "Hobro", logo: HobroLogo } as Club;
const AarhusSK = { id: "aarhussk", name: "Aarhus SK", logo: AarhusSkLogo } as Club;
const NTSK = { id: "ntsk", name: "NTSK", logo: NtskLogo } as Club;
const RoyalBern = { id: "royalbern", name: "Royal Bern", logo: RoyalBernLogo } as Club;
const Drammen = { id: "drammen", name: "Drammen", logo: DrammenLogo } as Club;
const StPatricks = { id: "stpatrickscollege", name: "St. Patricks", logo: StPatricksLogo } as Club;
const TikiTakaBergen = { id: "tikitakabergen", name: "TikiTakaBergen", logo: TikiTakaLogo } as Club;
const PambalSquad = { id: "pambalsquad", name: "Pambal Squad", logo: "" } as Club;
const ScBlueStarLausanne = { id: "scbluestarlausanne", name: "SC Blue Star Lausanne", logo: BlueStarsLogo } as Club;
const TSVEnnepetal = { id: "tsvennepetal", name: "TSV Ennepetal", logo: EnnepetalLogo } as Club;
const Vejle = { id: "vejle", name: "Vejle", logo: VejleLogo } as Club;
const SvendborgTFC = { id: "SvendborgTfc", name: "Svendborg TFC", logo: SvendborgLogo } as Club;
const GrindstedYoungstars = {
  id: "grindstedyoungstars",
  name: "Grindsted Youngstars",
  logo: grindstedYoungstarsLogo,
} as Club;
const Minnel = { id: "Minnel", name: "Minnel", logo: MinnelLogo } as Club;

export interface ExtendedTeamType extends TeamType {
  nationality: Nationality;
  potMatches: number[];
  potId: number;
  opponents: Array<ExtendedTeamType>;
}

export interface Pot {
  potId: number;
  teams: Array<ExtendedTeamType>;
}

export const pot1 = {
  potId: 1,
  teams: [
    {
      id: getGuid(),
      club: DantamIf,
      teamName: "Dantam A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: DantamIf,
      teamName: "Dantam B",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: UniqueFc,
      teamName: "Unique Red",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TikiTakaBergen,
      teamName: "Tiki Taka",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Sønderborg,
      teamName: "Sønderborg",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TfcFredericia,
      teamName: "TFC Fredericia White",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: UniqueFc,
      teamName: "Unique Black",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Otsc,
      teamName: "OTSC A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
  ],
} as Pot;

export const pot2 = {
  potId: 2,
  teams: [
    {
      id: getGuid(),
      club: Nordavind,
      teamName: "Nordavind White",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: StPatricks,
      teamName: "SPC France",
      nationality: Nationality.FRANCE,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Otsc,
      teamName: "OTSC B",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Everest,
      teamName: "Everest",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Crew74,
      teamName: "Crew 74 Wolves",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Hobro,
      teamName: "Hobro Black",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Minnel,
      teamName: "Minnel FC",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Gtfc,
      teamName: "GTFC",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
  ],
} as Pot;

export const pot3 = {
  potId: 3,
  teams: [
    {
      id: getGuid(),
      club: Hobro,
      teamName: "Hobro Blue",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: RoyalBern,
      teamName: "SC Royal Bern",
      nationality: Nationality.SWITZERLAND,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: NTSK,
      teamName: "NTSK",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Nordavind,
      teamName: "Nordavind Black",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Otsc,
      teamName: "OTSC C",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: AarhusSK,
      teamName: "Aarhus SK",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Crew74,
      teamName: "Crew 74 Warriors",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TfcFredericia,
      teamName: "TFC Fredericia Blue",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
  ],
} as Pot;

export const pot4 = {
  potId: 4,
  teams: [
    {
      id: getGuid(),
      club: Drammen,
      teamName: "Drammen TSK",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: PambalSquad,
      teamName: "Pambal-Squad",
      nationality: Nationality.FRANCE,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Vejle,
      teamName: "Vejle-Horsens TFC",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: GrindstedYoungstars,
      teamName: "Grindsted Youngstars",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: ScBlueStarLausanne,
      teamName: "SC Blue Star",
      nationality: Nationality.SWITZERLAND,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: SvendborgTFC,
      teamName: "Svendborg TFC",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: DantamIf,
      teamName: "Dantam C",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Hobro,
      teamName: "Hobro White",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
  ],
} as Pot;
