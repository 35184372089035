import { Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

function GeneratorErrorPage() {
  return (
    <div className="error-page">
      <SentimentVeryDissatisfiedIcon sx={{ color: "white", fontSize: 100, marginTop: "200px" }} />
      <Typography sx={{ textAlign: "center", marginTop: "20px", color: "white" }} variant="h2">
        Fejl
      </Typography>
      <Typography sx={{ textAlign: "center", marginTop: "30px", color: "white" }} variant="h5">
        Lodtrækningen kunne ikke gennemføres.
      </Typography>
    </div>
  );
}

export default GeneratorErrorPage;
