import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
  SvgIcon,
  Container,
  Checkbox,
} from "@mui/material";
import useConfirm from "../../../hooks/useConfirm";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setEditingUser, setUsers } from "../../../features/users/usersSlice";
import { ROLES, User } from "../../../types/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { avatars } from "../components/avatars";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Scrollbar } from "../components/scrollbar";
import { TableCellIconStyles } from "../../../styles/theme";

export const Users = () => {
  const [Dialog, confirmDelete] = useConfirm(
    "Er du sikker?",
    `Er du sikker på at du vil slette denne bruger? Det er ikke muligt at fortryde dette valg, og der kan ikke oprettes en bruger med samme email igen.`
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = useAppSelector((state) => state.users.users);

  useEffect(() => {
    /*     fetchAllUsers().then((data) => {
      const result = Object.keys(data).map((key) => {
        return { uid: key, ...data[key] };
      });
      dispatch(setUsers(result));
    });
    dispatch(setEditingUser(undefined)); */
  }, []);

  const editUserHandler = (user: User) => {
    dispatch(setEditingUser(user));
    navigate(ROUTES.SUPER_ADMIN_CREATE_USERS);
  };

  const deleteUserHandler = async (uid: string) => {
    /*     const confirmed = await confirmDelete();

    if (confirmed) {
      dbDeleteUserDetails(uid)
        .then(() => {
          dispatch(setUsers([...users].filter((user) => user.id !== uid)));
        })
        .catch((err) => {
          console.error(err);
        });
    } */
  };

  return (
    <>
      {Dialog()}
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Brugere</Typography>
            <div>
              <Button
                onClick={() => navigate(ROUTES.SUPER_ADMIN_CREATE_USERS)}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                Opret
              </Button>
            </div>
          </Stack>
          <Card>
            <Scrollbar>
              <Box sx={{ minWidth: 300 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Navn</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users
                      .filter((u) => u.role !== ROLES.SUPER_ADMIN)
                      .map((user, i) => {
                        return (
                          <TableRow hover key={user.id}>
                            <TableCell>
                              <Stack alignItems="center" direction="row" spacing={2}>
                                <Avatar src={avatars[i]} />
                                <Typography variant="subtitle2">{`${user.firstname} ${user.lastname}`}</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell
                              sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "40px" }}
                            >
                              <ModeEditOutlineOutlinedIcon sx={TableCellIconStyles} onClick={() => editUserHandler(user)} />
                              <DeleteOutlineOutlinedIcon
                                sx={TableCellIconStyles}
                                onClick={() => deleteUserHandler(user.id ?? "")}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </Scrollbar>
          </Card>
        </Stack>
      </Container>
    </>
  );
};
