import {
  getDraws,
  getGoalDifference,
  getGoalsAway,
  getGoalsFor,
  getLoses,
  getPlays,
  getPoints,
  getWins,
  sortTeams,
} from "../../utils";
import ballLogo from "../../assets/icons/ball-logo.png";
import { GroupType, Match, TeamType } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useAppDispatch } from "../../hooks";
import { setSelectedTeams } from "../../features/teams/TeamsSlice";
import { useMemo } from "react";

interface GroupProps {
  group: GroupType;
  groupMatches: Match[];
}

function Group(props: GroupProps) {
  const { group, groupMatches } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const showMatches = (e: any, teams?: TeamType[]) => {
    e.stopPropagation();
    if (!teams || teams?.length === 0) return;
    dispatch(setSelectedTeams(teams));
    navigate(ROUTES.TEAM_MATCHES);
  };

  const sortByMutuality = useMemo(() => {
    if (group.pulje.id === "5916f9f1-3a1b-4e9d-aa40-6fc0b122a493") {
      console.log("Pulje is senior. Ingen indbyrdes");
      return false;
    } else if (group.pulje.id === "5798f0c0-f27e-41e7-9b1d-bb60f970bf72") {
      console.log("Pulje is U19. Ingen indbyrdes");
      return false;
    } else {
      console.log("Not senior or U19");
      return true;
    }
  }, [group]);

  return (
    <div key={group.id} className="table" onClick={(e) => showMatches(e, group.teams)}>
      <div className="table-content">
        {
          <div className="row header">
            <div className="club-column">
              <p className="group-name">GROUP {group.name.toUpperCase()}</p>
            </div>
            <div className="points-column">
              <p className="letter">P</p>
              <p className="letter">W</p>
              <p className="letter">D</p>
              <p className="letter">L</p>
              <p className="letter">F</p>
              <p className="letter">A</p>
              <p className="letter">GD</p>
              <p className="letter">PTS</p>
            </div>
          </div>
        }
      </div>
      <div className="table-content">
        {group.teams &&
          sortTeams(group.teams, groupMatches, sortByMutuality).map((team, index) => {
            return (
              <div key={team.id} className="row">
                <div className="club-column">
                  <p className="order-column">{index + 1}</p>
                  <img src={team.club?.logo ? team.club?.logo : ballLogo} alt="" height={14} />
                  <span onClick={(e) => showMatches(e, [team])} className="team-name">
                    {team.teamName}
                  </span>
                </div>

                <div className="points-column">
                  <p className="number">{getPlays(team.id, groupMatches)}</p>
                  <p className="number">{getWins(team.id, groupMatches)}</p>
                  <p className="number">{getDraws(team.id, groupMatches)}</p>
                  <p className="number">{getLoses(team.id, groupMatches)}</p>
                  <p className="number">{getGoalsFor(team.id, groupMatches)}</p>
                  <p className="number">{getGoalsAway(team.id, groupMatches)}</p>
                  <p className="number">{getGoalDifference(team.id, groupMatches)}</p>
                  <p className="number" style={{ color: "white" }}>
                    {getPoints(team.id, groupMatches)}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Group;
