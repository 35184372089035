import { CSSProperties, useEffect, useMemo, useState } from "react";
import { Pot, pot1, pot2, pot3, pot4, ExtendedTeamType } from "./data";
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { findOpponent, getEmoji, getMostFrequentNation } from "./utils";
import { Match, ROLES } from "../../types/types";
import { getGuid } from "../../utils";
import ErrorPage from "../other/errorPage";
import ShuffleText from "./ShuffleText";
import DantamImg1 from "./images/dt1.png";
import DantamImg2 from "./images/dt2.png";
import { useAppSelector } from "../../hooks";
import GeneratorErrorPage from "./generatorErrorPage";

const CardStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  borderRadius: 0,
  paddingTop: "50px",
  flexWrap: "wrap",
  backgroundColor: "transparent",
  boxShadow: "none !important",
};

const TableCellStyle: SxProps = {
  backgroundColor: "#02024cc2",
  borderBottomColor: "transparent",
  textAlign: "center",
  width: "300px",
  height: "50px",
  padding: 0,
};

export const Dantam = () => {
  const [matches, setMatches] = useState<Array<Match & { visited: boolean }>>([]);
  const pots = [pot1, pot2, pot3, pot4];
  const [selectedPotId, setSelectedPotId] = useState<string>(pot1.potId.toString());
  const [selectedTeam, setSelectedTeam] = useState<ExtendedTeamType>();
  const [drawing, setDrawing] = useState(false);
  const [counter, setCounter] = useState(1);
  const [drawSucceeded, setDrawSucceeded] = useState(false);

  const admin = useAppSelector(state => state.app.admin)

  useEffect(() => {
    import("../../styles/components/dantam.css").then((module) => {
      try1000();
    });
  }, []);

  useEffect(() => {
    if (!selectedTeam) return;

    const handleKeyPress = (event: any) => {
      if (event.key === "Enter") {
        setDrawing(true);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedTeam]);

  useEffect(() => {
    if (!drawing || !selectedTeam) return;

    const interval = setInterval(() => {
      const result = matches
        .filter((match) => match.homeTeam?.id === selectedTeam.id || match.awayTeam?.id === selectedTeam.id)
        .find((match) => {
          if (match.homeTeam?.id === selectedTeam.id && (match.awayTeam as ExtendedTeamType)?.potId === counter) {
            return true;
          }
          if (match.awayTeam?.id === selectedTeam.id && (match.homeTeam as ExtendedTeamType)?.potId === counter) {
            return true;
          }
          return false;
        });

      setMatches((prev) =>
        prev.map((match) => {
          if (match.id === result?.id) {
            return {
              ...match,
              visited: true,
            };
          } else {
            return match;
          }
        })
      );
      setCounter((prev) => prev + 1);
    }, 1000);

    if (counter === 5) {
      clearInterval(interval);
      setDrawing(false);
      setCounter(1);
    }

    return () => clearInterval(interval);
  }, [drawing, counter]);

  const selectedPotTeams = useMemo(() => {
    if (!selectedPotId) return [];
    return pots.find((pot) => pot.potId === parseInt(selectedPotId))?.teams;
  }, [selectedPotId]);

  const try1000 = () => {
    for (let index = 0; index < 100000; index++) {
      const _pots = JSON.parse(JSON.stringify(pots));
      if (generate(_pots)) {
        setDrawSucceeded(true);
        break;
      }
    }
  };

  const generate = (pots: Array<Pot>) => {
    const _matches: Array<Match> = [];

    pots.forEach((mainPot) => {
      for (let index = 0; index < mainPot.teams.length; index++) {
        const team = mainPot.teams[index];

        pots.forEach((subPot, index) => {
          if (!team.potMatches.includes(subPot.potId)) {
            const modstander = findOpponent(team, subPot, _matches);
            if (!modstander) {
              return false;
            }

            const matchTeamplate = {
              awayTeamPoints: 0,
              duration: 10,
              endTime: 0,
              homeTeamPoints: 0,
              id: getGuid(),
              location: {
                delay: 0,
                id: "hal1",
                location: "Hal 1",
                tournament: {
                  id: "dantamcup",
                  date: 123,
                  isActive: true,
                  isGoingLive: true,
                  link: "",
                  name: "Dantam Cup",
                  showInTournamentDates: true,
                },
              },
            } as Match;

            // Swap hver gang for at undgå samme hold er hjemmehold
            if (index % 2) {
              _matches.push({ ...matchTeamplate, homeTeam: team, awayTeam: modstander });
            } else {
              _matches.push({ ...matchTeamplate, homeTeam: modstander, awayTeam: team });
            }

            team.potMatches.push(subPot.potId);
            team.opponents.push(modstander);
            modstander.potMatches.push(mainPot.potId);
            modstander.opponents.push(team);
          }
        });
      }
    });

    if (test(pots, _matches)) {
      setMatches(
        _matches.map((match) => {
          return { ...match, visited: false };
        })
      );
      return true;
    } else {
      return false;
    }
  };

  const findTableOpponent = (myTeam: ExtendedTeamType, potId: number) => {
    const result = matches
      .filter((match) => (match.homeTeam?.id === myTeam.id || match.awayTeam?.id === myTeam.id) && match.visited)
      .map((match) => {
        if (match.homeTeam?.id === myTeam.id) {
          return match.awayTeam;
        } else {
          return match.homeTeam;
        }
      }) as ExtendedTeamType[];
    return result.find((obj) => obj.potId === potId);
  };

  const test = (pots: Array<Pot>, _matches: Array<Match>) => {
    const allTeams = pots.flatMap((obj) => obj.teams);

    return (
      allTeams.every((team) => getMostFrequentNation(team).numberOfOpponents <= 3) &&
      allTeams.every((team) => team && team.opponents.length === 4) && // Every team has 4 opponents
      _matches.length === 64 // Exactly 64 matches
    );
  };

  const getOtherTeam = (match: Match, myTeam?: ExtendedTeamType) => {
    if (!myTeam) return;
    return match.homeTeam?.id === myTeam.id ? (match.awayTeam as ExtendedTeamType) : (match.homeTeam as ExtendedTeamType);
  };

  const setSelectedTeamHandler = (team: ExtendedTeamType) => {
    setSelectedTeam(team);
  };

  const onModalCloseHandler = () => {
    if (drawing) return;
    setSelectedTeam(undefined);
  };

  if (admin?.role !== ROLES.ADMIN && admin?.role !== ROLES.SUPER_ADMIN) return <ErrorPage />;
  if (!drawSucceeded) return <GeneratorErrorPage />;

  return (
    <>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      <Dialog onClose={onModalCloseHandler} open={!!selectedTeam}>
        <DialogTitle
          onClick={() => {
            setDrawing(false);
            setCounter(1);
          }}
          sx={{
            backgroundColor: "black",
            textAlign: "center",
            color: "white",
            letterSpacing: 2,
            textTransform: "uppercase",
          }}
        >
          {selectedTeam?.teamName}

          <img style={{ marginLeft: "10px", height: "15px" }} src={getEmoji(selectedTeam?.nationality)} />
        </DialogTitle>
        <DialogContent sx={{ height: "600px", padding: 0, backgroundColor: "#111927" }}>
          <Table sx={{ height: "100%" }}>
            <TableBody>
              {matches
                .filter((match) => match.homeTeam?.id === selectedTeam?.id || match.awayTeam?.id === selectedTeam?.id)
                .map((match, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={TableCellStyle}>
                        <Typography sx={{ color: "white", textTransform: "uppercase" }} variant="subtitle1">
                          Pot {getOtherTeam(match, selectedTeam)?.potId}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...TableCellStyle,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          border: 0,
                        }}
                      >
                        <div className={`reveal-text ${!match.visited ? "show-team" : "hide-team"}`}>
                          <>{<ShuffleText />}</>
                        </div>

                        <div className={`reveal-text ${match.visited ? "show-team" : "hide-team"}`}>
                          <Typography
                            sx={{ display: "flex", alignItems: "center", color: "white", textTransform: "uppercase" }}
                            variant="subtitle1"
                          >
                            <img
                              style={{ marginRight: "10px" }}
                              height={20}
                              src={getOtherTeam(match, selectedTeam)?.club?.logo ?? ""}
                            />

                            {`${getOtherTeam(match, selectedTeam)?.teamName}`}
                            <img
                              style={{ marginLeft: "10px" }}
                              height={10}
                              src={getEmoji(getOtherTeam(match, selectedTeam)?.nationality)}
                            />
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      <Stack sx={{ width: "100%", height: "100%" }}>
        <Card sx={CardStyle}>
          <Box
            key={pot1.potId}
            sx={{
              display: "flex",
              height: "600px",
              justifyContent: "center",
            }}
          >
            <Table sx={{ borderRight: "5px solid transparent", width: "300px" }}>
              <TableHead sx={{ height: "100px" }}>
                <TableRow>
                  <TableCell sx={{ background: "#02024cc2 !important", color: "white !important", textAlign: "center" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedPotId}
                      label="Pot"
                      onChange={(e) => setSelectedPotId(e.target.value)}
                      sx={{ color: "white" }}
                      fullWidth
                    >
                      {pots.map((pot) => (
                        <MenuItem key={pot.potId} value={pot.potId}>
                          Pot {pot.potId}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPotTeams?.map((team, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => setSelectedTeamHandler(team)}
                      key={index}
                      sx={{ cursor: "pointer", height: "50px" }}
                    >
                      <TableCell
                        sx={{ backgroundColor: "#02024cc2", borderBottomColor: "transparent", textAlign: "center" }}
                      >
                        {
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: matches
                                .filter((match) => match.homeTeam?.id === team.id || match.awayTeam?.id === team.id)
                                .every((match) => match.visited)
                                ? "#11b981"
                                : "white",
                              textTransform: "uppercase",
                              fontWeight: team.id === selectedTeam?.id ? "700" : "500",
                              textDecoration: team.id === selectedTeam?.id ? "underline" : "none",
                              textUnderlineOffset: "12px",
                            }}
                            variant="subtitle1"
                          >
                            <img style={{ marginRight: "10px" }} height={20} src={team.club?.logo ?? ""} />

                            {`${team.teamName}`}
                            <img style={{ marginLeft: "10px" }} height={10} src={getEmoji(team.nationality)} />
                          </Typography>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <Table sx={{ width: "70%" }}>
              <TableHead sx={{ height: "100px", borderBottom: "1px solid rgba(255, 255, 255, 0.25)" }}>
                <TableRow>
                  {pots.map((pot) => (
                    <TableCell
                      key={pot.potId}
                      sx={{
                        background: "#02024cc2 !important",
                        color: "white !important",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle1">Pot {pot.potId}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {pots
                  .filter((obj) => obj.potId.toString() === selectedPotId.toString())
                  .flatMap((pot) => pot.teams)
                  ?.map((team) => {
                    return (
                      <TableRow key={team.id}>
                        {pots.map((pot) => (
                          <TableCell key={pot.potId} sx={TableCellStyle}>
                            {
                              <Typography
                                sx={{ ...CustomCellStyle, display: "flex", justifyContent: "center", alignItems: "center" }}
                                variant="subtitle1"
                              >
                                <img
                                  style={{ marginRight: "10px" }}
                                  height={16}
                                  src={findTableOpponent(team, pot.potId)?.club?.logo ?? ""}
                                />
                                {`${findTableOpponent(team, pot.potId)?.teamName ?? "-"}`}
                                <img
                                  style={{ marginLeft: "10px" }}
                                  height={10}
                                  src={getEmoji(findTableOpponent(team, pot.potId)?.nationality)}
                                />
                              </Typography>
                            }
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
            <img src={DantamImg2} alt="" style={ImageStyle} />
            <img src={DantamImg1} alt="" style={ImageStyle} />
          </Box>
        </Card>
      </Stack>
    </>
  );
};

const CustomCellStyle: SxProps = {
  color: "white",
  textTransform: "uppercase",
};

const ImageStyle: CSSProperties = {
  display: "flex",
  width: "10%",
  margin: "30px 40px",
};
